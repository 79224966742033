<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl py-0 px-3>
            <v-flex xs2 sm1 pt-3 offset-sm11 offset-xs10 ref="actionBar">
                <div class="text-right">
                    <v-btn-toggle
                            color="primary"
                            dense
                            multiple
                    >
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn @click="createDialog =true">
                        <v-icon small dark v-on="on">ti ti-plus</v-icon>
                    </v-btn>
                    </template>
                    <span>{{ $t('message.createNew') }}</span>
                    </v-tooltip>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                v-on="{...tooltip, ...menu}">
                                <v-icon small dark v-on>ti ti-export</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.export') }}</span>
                      </v-tooltip>
                    </template>
                        <v-list>
                            <v-list-item
                            @click="">
                                <v-list-item-title>{{ $t('message.asPdf') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="">
                                <v-list-item-title>{{ $t('message.asExcel') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn-toggle>
                </div>
            </v-flex>
            <v-flex xs12 sm12 pt-3 ref="searchBar">
                <v-form
                        lazy-validation>
                    <v-layout row class="dense-inputs">
                      <v-flex xs6 sm2>
                          <v-autocomplete
                                  :label="$t('message.country')"
                                  :items="countries"
                                  multiple
                                  clearable
                                  solo
                                  dense
                                  background-color="white"
                                  hide-details
                                  v-model="filters['country_id']"
                                  item-value="value"
                                  item-text="text"
                          ></v-autocomplete>
                      </v-flex>
                      <v-flex xs6 sm2>
                          <v-autocomplete
                                  :label="$t('message.state')"
                                  :items="states"
                                  multiple
                                  clearable
                                  solo
                                  dense
                                  background-color="white"
                                  hide-details
                                  v-model="filters['state']"
                                  item-value="value"
                                  item-text="text"
                          ></v-autocomplete>
                      </v-flex>
                      <v-flex xs6 sm2>
                          <v-autocomplete
                                  :label="$t('message.scac')"
                                  :items="scacs"
                                   multiple
                                   clearable
                                   solo
                                   dense
                                   background-color="white"
                                   hide-details
                                   v-model="filters['scac']"
                                   item-value="id"
                                   item-text="text"
                          ></v-autocomplete>
                      </v-flex>
                      <v-flex xs6 sm2>
                          <v-autocomplete
                                  :items="statuses"
                                  :label="$t('message.status')"
                                   multiple
                                   clearable
                                   solo
                                   dense
                                   background-color="white"
                                   hide-details
                                   v-model="filters['status']"
                                   item-value="id"
                                   item-text="text"
                          ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12 sm4>
                          <v-text-field
                                  v-model="search.agent"
                                  append-icon="search"
                                  :label="$t('message.filter')"
                                  single-line
                                  hide-details
                                  background-color="yellow lighten-2"
                                  solo
                          ></v-text-field>
                      </v-flex>
                    </v-layout>
                </v-form>
            </v-flex>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-data-table
                            :headers="headers"
                            :items="filteredItems"
                            :search="search.agent"
                            :footer-props="pagination"
                            item-key="id"
                            class="elevation-1 appic-table"
                            :key="tableKey"
                            :items-per-page="25"
                            dense
                            calculate-widths
                            fixed-header
                            :height="tableheight"
                    >
                        <template v-slot:item="props">
                            <tr>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.country }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.title }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.scac }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.address1 }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.city }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.postcode }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.state }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.tel }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.fax }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.email }}</td>
                                <td @click="editShippingAgentProfile(props.item.id)">{{ props.item.web }}</td>
                                <td>
                                    <v-edit-dialog
                                            :return-value.sync="props.item.status"
                                            large
                                            persistent
                                            @save="save"
                                            @cancel="cancel"
                                            @open="open"
                                            @close="close"
                                    >
                                        <div class="text-center">
                                            <v-chip :color="getStatusColor(props.item.status)" dark x-small>{{ props.item.status }}</v-chip>
                                        </div>
                                        <template v-slot:input>
                                            <div class="mt-4 title">{{ $t('message.updateStatus') }}</div>
                                        </template>
                                        <template v-slot:input>
                                            <v-select
                                                    v-model="props.item.status"
                                                    :items="filters.statuses"
                                                    :placeholder="$t('message.status')"
                                                    label="Edit"
                                                    clearable
                                            ></v-select>
                                        </template>
                                    </v-edit-dialog>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:no-data>
                            {{ $t('message.noData') }}
                        </template>
                    </v-data-table>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
                v-model="createDialog"
                transition="dialog-bottom-transition"
                overlay=false
                scrollable
                max-width="600px"
                width="600px"
        >
            <v-card>
                <v-card-title>
                    <v-toolbar
                            color="success"
                            dark
                            flat
                            dense
                    >
                        <v-btn icon @click.native="createDialog = false" dark>
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t('message.createShippingAgent') }}</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md class="dense-inputs">
                        <v-form
                                ref="form"
                                lazy-validation>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.name') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.name"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingagent.name ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.standardCarrierAlphaCode') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.scac"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingagent.scac ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red-text">{{ $t('message.address1') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.address1"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingagent.address1 ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.address2') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.address2"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.address3') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.address3"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.city') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.city"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingagent.city ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.postcode') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.postcode"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingagent.postcode ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.stateProvince') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.state"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingagent.state ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.country') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-select
                                            v-model="shippingagent.country_id"
                                            solo
                                            dense
                                            required
                                            :rules="[v => !!v || '']"
                                            :items="filters.countries"
                                            :label="shippingagent.country_id ? '' : $t('message.required')"
                                            hide-details
                                    ></v-select>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.website') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.web"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.tel') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.tel"
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingagent.tel ? '' : $t('message.required')"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1>{{ $t('message.fax') }}</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.fax"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.email') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingagent.email"
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingagent.email ? '' : $t('message.required')"
                                            solo
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error lighten-2" @click.stop="close">{{$t('message.close')}}</v-btn>
                    <v-btn color="primary lighten-2" @click.save="save">{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { api } from "../../../api";
    import {mapGetters} from "vuex";
    import {mapActions} from "vuex";
    export default {
        name: "Listing",
        data() {
            return {
                loader: true,
                tmp: "",
                search: {
                  agent: "",
                },
                headers: [
                    {
                        text: "Country",
                        value: "country"
                    },
                    {
                        text: "Agent",
                        value: "title"
                    },
                    {
                        text: "SCAC",
                        value: "scac"
                    },
                    {
                        text: "Address",
                        value: "address1"
                    },
                    {
                        text: "City",
                        value: "city"
                    },
                    {
                        text: "Postcode",
                        value: "postcode"
                    },
                    {
                        text: "State/Province",
                        value: "state"
                    },
                    {
                        text: "Tel",
                        value: "tel"
                    },
                    {
                        text: "Fax",
                        value: "fax"
                    },
                    {
                        text: "Email",
                        value: "email"
                    },
                    {
                        text: "Website",
                        value: "web"
                    },
                    {
                        text: "Status",
                        value: "status",
                        class: "text-center"
                    }
                ],
                watch: {
                  params: {
                    handler() {
                      this.getDataFromApi().then(data => {
                        console.log("GETDATA");
                        this.items = data.items;
                      })
                    },
                    deep: true
                  }
                },
                items: [],
                statuses: [
                    {text: 'Active', value: 0},
                    {text: 'Suspended', value: 1}
                ],
                countries: [
                  {text: 'China', value: 5},
                  {text: 'Hong Kong', value: 27},
                  {text: 'Malaysia', value: 1},
                  {text: 'India', value: 41},
                  {text: 'Indonesia', value: 4}
                ],
                states: [
                  {text: 'Shanghai'},
                  {text: 'Fujian'},
                  {text: 'Ningbo'},
                  {text: 'Liaoning'},
                  {text: 'Guangdong'}
                ],
                scacs: [
                  {text: 'AWSL'},
                  {text: 'ANLC'},
                  {text: 'APLU'},
                  {text: 'BANQ'},
                  {text: 'CMDU'}
                ],
                filters: {
                    status: [],
                    country_id: [],
                    state: [],
                    scac: []
                },
                filter: {
                    country: [],
                    status: [],
                },
                pagination: {
                    page: 1,
                    itemsPerPageAllText: "$vuetify.dataFooter.itemsPerPageAll",
                    itemsPerPageOptions: [25, 40, 60, -1]
                },
                tableKey: 0,
                tableheight: "100vh",
                createDialog: false,
                shippingagent: {}
            }
        },
        computed: {
            //filterselected: function () {
                //return (this.filter.country.length + this.filter.status.length)
            //}
            filteredItems() {
              return this.items.filter(d => {
                return Object.keys(this.filters).every(f => {
                  return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                })
              })
            },
            params(nv) {
              return {
                query: this.search
              };
            }
        },
        mounted() {
            this.getTableData();
            this.handleResize();
            this.getCountries();
            this.getDataFromApi().then(data => {
              this.items = data.items;
            });
        },
        methods: {
            getTableData() {
                api
                    .get("appic/getshippingagents")
                    .then(response => {
                        this.loader = false;
                        this.items = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getCountries() {
                api
                    .get("appic/getcountries")
                    .then(response => {
                        this.loader = false;
                        this.countries = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getDataFromApi() {
              this.loading = true;
              return new Promise((resolve) => {
                let items = this.getItems();
                setTimeout(() => {
                  this.loading = false;
                  resolve({
                    items,
                  });
                });
              });
            },
            getItems() {
              return []
            },
            editShippingAgentProfile(shipping_agent_id) {
                api
                    .get(`appic/getshippingagent/${shipping_agent_id}`)
                    .then(response => {
                        this.loader = false;
                        this.shippingagent = response.data;
                        this.createDialog = true
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            resetFilter() {
                this.filter.country = []
                this.filter.status = []
            },
            handleResize() {
                this.tableheight = window.innerHeight - (137 + this.$refs.breadcrumbBar.$el.clientHeight + this.$refs.searchBar.clientHeight + this.$refs.filterBar.clientHeight);
            },
            getStatusColor(status) {
                if (status == 'Active') return 'green'
                else return 'grey'
            },
            save () {
              if (this.$refs.form.validate()) {
                this.createDialog = false;
                this.$refs.form.reset();
              }
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Data saved'
            },
            cancel () {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Canceled'
            },
            open () {
                this.snack = true
                this.snackColor = 'info'
                this.snackText = 'Dialog opened'
            },
            close () {
                this.createDialog = false;
                this.$refs.form.reset();
                console.log('Dialog closed')
            },
        },
        created() {
            window.addEventListener('resize', this.handleResize)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        }
    }
</script>

<style scoped>

</style>
